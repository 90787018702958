<template>
    <div class="row">
        <div class="col-md-12">
            <button @click="$router.push('cfadd')" class="btn btn-primary">Add</button>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Category</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="cf in Cfs" :key="cf.key">
                        <td>{{ cf.name }}</td>
                        <td>{{ cf.type }}</td>
                        <td>{{ cf.category }}</td>
                        <td>
                            <router-link :to="{name: 'CFEdit', params: { id: cf.name, type: cf.type, category: cf.category}}" class="btn btn-primary">Edit
                            </router-link>
                            <button @click.prevent="deleteCf(cf.name)" class="btn btn-danger">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                Cfs: [],
                currentTenant: "",
            }
        },
        
        created() {
            this.currentTenant = this.$tenant;
            this.updateservices();
            
        },
        methods: {
            updateservices() {
                let dbRef = db.collection('tenants').doc(this.currentTenant);
            dbRef.get().then((doc) => {
                this.Cfs = doc.data().fields.sort(function(a, b){
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });
                //console.log(this.cf)
            }).catch((error) => {
                console.log(error)
            })
            },
            deleteCf(id){
              if (window.confirm("Do you really want to delete?")) {
                  this.Cfs.splice(this.Cfs.map((el) => el.name).indexOf(id), 1)
                db.collection('tenants').doc(this.currentTenant).update({fields: this.Cfs}).then(() => {
                    console.log("Service Location successfully updated!");
                    this.$router.push('/cfl')
                }).catch((error) => {
                    console.log(error);
                });
              }
            }
        }
    }
</script>

<style>
    .btn-primary {
        margin-right: 12px;
    }
</style>